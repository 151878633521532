<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pr-3">
      <v-card class="radius-card mb-3"> </v-card>
      <v-card class="radius-card px-3" :loading="loader">
        <!-- <v-card class="temp-table ma-5"> -->

        <div class="d-flex justify-space-between pa-3">
          <v-row>
            <v-col cols="6">
              Psychologist List
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                solo
                dense
                rounded
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup="fetchPsycho"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="pa-3">
          <v-row>
            <v-col cols="2">
              <p class="mb-0">Tampilkan</p>
              <div class="d-flex align-center">
                <v-select
                  :items="listLimit"
                  v-model="perPage"
                  dense
                  hide-details
                  outlined
                  @change="fetchPsycho"
                ></v-select>
                <p class="ml-1 mb-0">Data</p>
              </div>
            </v-col>
            <v-col cols="3" v-if="listBidang">
              <p class="mb-0">Pilih Bidang</p>
              <v-select
                :items="listBidang"
                v-model="bidang"
                item-text="nama"
                item-value="id"
                dense
                hide-details
                outlined
                @change="fetchPsycho"
              ></v-select>
            </v-col>
            <v-col cols="2" v-if="listBidang">
              <p class="mb-0">Sortir</p>
              <div class="d-flex align-center">
                <v-select
                  :items="listSort"
                  v-model="sort"
                  item-text="nama"
                  item-value="val"
                  dense
                  hide-details
                  outlined
                  @change="fetchPsycho"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="2" v-if="role == 1 || role == 3">
              <div style="height:24px"></div>
              <v-btn to="/admin/import-psy" dark color="purple" depressed
                ><v-icon>mdi-microsoft-excel</v-icon> Import Psycologist</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <div v-if="psycholog">
          <v-data-table
            :headers="headers"
            hide-default-footer
            :page.sync="page"
            :items-per-page="perPage"
            :items="psycholog.data"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.nama_lengkap`]="{ item }">
              <div style="width:200px" class="d-flex align-center">
                <p
                  :class="
                    `mb-0 mr-2 ${item.prioritas ? 'font-weight-bold' : ''}`
                  "
                >
                  {{ item.nama_lengkap }}
                </p>
                <v-icon
                  v-if="item.prioritas"
                  content="Psikolog Prioritas"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 120,
                  }"
                  small
                  color="orange"
                >
                  mdi-star</v-icon
                >
              </div>
            </template>
            <template v-slot:[`item.last_login`]="{ item }">
              <div style="width:100px">
                {{ item.last_login }}
              </div>
            </template>
            <template v-slot:[`item.bidang`]="{ item }">
              <v-chip
                :color="
                  item.bidang_id == 1
                    ? '#64b5f6'
                    : item.bidang_id == 2
                    ? '#ffc107'
                    : '#81c784'
                "
                dark
              >
                {{
                  item.bidang_id == 1
                    ? "Psikologi Klinis"
                    : item.bidang_id == 2
                    ? "Psikologi Industri & Organisasi"
                    : "Psikologi Pendidikan"
                }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  content="Lihat Detil"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  @click.prevent="view(item)"
                >
                  <v-icon dark small> mdi-eye </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  :color="item.prioritas ? 'grey' : 'orange'"
                  class="mr-2"
                  :content="
                    item.prioritas ? `Hentikan prioritas` : 'Jadikan prioritas'
                  "
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 120,
                  }"
                  @click.prevent="updateData(item, 'prioritas')"
                >
                  <v-icon dark small> mdi-star-shooting </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  class="mr-2"
                  color="blue"
                  content="Edit"
                  v-if="role == 1"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  :to="`/admin/update-psycolog/${item.id}`"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  :color="item.hide ? 'indigo' : 'red'"
                  class="mr-2"
                  :content="
                    item.hide ? 'Tampilkan ke user' : 'Sembunyikan dari user'
                  "
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 120,
                  }"
                  @click.prevent="updateData(item, 'hide')"
                >
                  <v-icon dark small> mdi-account-eye </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  content="Hapus"
                  v-if="role == 1"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  @click.prevent="deletePsy(item)"
                >
                  <v-icon dark small> mdi-trash-can </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center" v-if="loader">Memuat ...</div>
          <div class="text-center pa-2">
            <v-pagination
              v-model="page"
              :total-visible="7"
              @input="fetchPsycho"
              :length="psycholog.meta.last_page"
            ></v-pagination>
          </div>
        </div>
        <div class="pa-2"></div>
        <!-- </v-card> -->
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "listPsychologist",
  computed: {
    ...mapState({
      psycholog: (state) => state.admin.psycholog,
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      listLimit: [5, 10, 20, 50, 70, 100],
      listBidang: null,
      listSort: [
        { nama: "Terlama", val: "" },
        { nama: "Terbaru", val: "-created_at" },
      ],
      sort: "",
      bidang: "",
      search: "",
      page: 1,
      perPage: 10,
      loader: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama_lengkap" },
        { text: "PHONE", value: "no_hp" },
        { text: "BIDANG", value: "bidang" },
        { text: "EMAIL", value: "email" },
        { text: "LAST LOGIN", value: "last_login" },
        { text: "ACTIONS", value: "actions" },
      ],
      param: this.$route.params.id,
      update_psy: false,
      pocket: null,
    };
  },
  created() {
    this.fetchPsycho();
    this.$store.dispatch("fields/listFields").then((field) => {
      this.listBidang = field.data.data;
      this.listBidang.push({ id: "", nama: "Semua Bidang" });
    });
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.fetchPsycho();
    },
  },
  methods: {
    fetchPsycho() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        bidang: this.bidang,
        sort: this.sort,
        type: this.$route.params.type,
        value: this.$route.params.value,
      };
      this.loader = true;
      this.$store.dispatch("admin/listPsyholog", data).then(() => {
        this.loader = false;
      });
    },
    view(item) {
      this.$router.push(`/pam/${item.id}`);
    },
    updateData(item, type) {
      this.loading = true;
      let data = {
        [type]: item[type] == 0 ? 1 : 0,
      };
      let id = item.id;
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("psycholog/updateProfile", { id: id, data: data })
            .then((data) => {
              this.loading = false;
              this.fetchPsycho();
              Swal.fire({
                icon: "success",
                title: data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000,
              });
            })
            .catch((err) => {
              for (let i in err) {
                this.alert("error", `${err[i][0]}`);
              }
              this.loading = false;
            });
        }
      });
    },
    deletePsy(item) {
      let data = {
        path: `admin/delete-psikolog`,
        body: {
          id: item.id,
        },
      };
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("postApi", data).then(() => {
            this.fetchPsycho(); //if success refetch data all campus
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true,
            });
          });
        }
      });
    },
    importList(e) {
      const file = e.target.files[0];
      const data = new FormData();
      data.append("file", file);
      this.$store.dispatch("admin/importUser", data).then((data) => {
        this.alert("success", data.message);
      });
    },
    alert(type, title) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
      });
    },
  },
};
</script>

<style></style>
